export default {
    testFeatureFlag: 'test',
    newPdfViewer: 'new-pdf-viewer',
    showTextAnnotateViewButton: 'show-text-annotate-view-button',
    disableIntegrationRPA: 'disable-rpa-integration',
    enableProfileUpdating: 'enable-profile-updating',
    disableCaptureEvents: 'disable-capture-events',
    enableSessionRecording: 'enable-session-recording',
    enableToggleFinishDocument: 'enable-toggle-finish-document',
    enableRetryWorkflowButton: 'enable-retry-workflow-button',
    enableReloadDocumentButton: 'enable-reload-document-button',
    acadreLoginEnabled: 'enable-acadre-login',
    enableCPRInput: 'enable-cpr-input',
    enableHelpDeskButton: 'enable-help-desk-button'
}