<template>
  <div>
    <div v-if="searchPDFResults.length > 0">
      <div v-for="(docSearch, docSearchIndex) in searchPDFResults" :key="docSearchIndex">
        <!-- Document title text -->
        <a class="cursor-pointer" @click="goToResult(docSearch[0], docSearch[0].document_id, 1)">
          {{ docSearch[0].display_name }}
        </a>
        <div v-for="(pageResult, index) in docSearch" :key="pageResult.page_number">
          <!-- Document title and other elements -->
          <a class="text-sm underline cursor-pointer" @click="goToResult(pageResult, pageResult.document_id, pageResult.page_number)">
            Side {{ pageResult.page_number }}
          </a>
          <input
            type="checkbox"
            class="ml-1 mb-1"
            autocomplete="off"
            :checked="areAllSelected(pageResult.page_matches)"
            @change="togglePageSelection(docSearchIndex, index)"
          >
          <div v-for="(pageMatch, pageMatchIndex) in pageResult.page_matches" :key="pageMatchIndex">
            <input
              v-if="!redactionExists(pageMatch, pageResult.page_number, pageResult.document_id)"
              v-model="pageMatch.selected"
              type="checkbox"
              :disabled="redactionExists(pageMatch, pageResult.page_number, pageResult.document_id)"
            >
            <span class="ml-2">
              <span v-for="(matchHit, matchIdx) in pageMatch.match" :key="matchIdx">
                {{ matchHit.text+" " }} 
              </span>
              <span v-if="pageMatch.match[0].ner" class="bg-blue-500 rounded-full 2xl:mx-1 px-2 text-white text-xs">
                {{ pageMatch.match[0].ner }}
              </span>
              <span v-if="redactionExists(pageMatch, pageResult.page_number, pageResult.document_id)" class="bg-red-500 rounded-full 2xl:mx-1 px-2 text-white text-xs">
                Allerede overstreget
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="space-x-2 m-2">
        <standard-button
          button-type="standard"
          :button-text="selectAllButtonText"
          @click.native="toggleAllPagesSelection"
        />
      </div>
      <div class="space-x-2 m-2">
        <standard-button
          button-type="success"
          button-text="Overstreg valgte"
          @click.native="lawAndRedactResults()"
          :disabled="isAResultChecked"
        />
      </div>
    </div>
    <div v-else-if="waitingForSearch">
      Søger...
    </div>
    <div v-else>
      Ingen Resultater Fundet
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import LawSelection from "@/components/controls/LawSelection.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "RedactionsSearchCheck",
  components: {
    "standard-button": StandardButton,
  },
  data() {
    return {
      pages: [
        // Your pages array with items and selection state
      ],
      selectedLaw: {},
      pageCheckValue: [],
      openDocument: undefined
    };
  },
  computed: {
    ...mapState([
      "redactions",
      "openProcess",
      "openFiles",
      "openFile",
      "searchPDFResults",
      "pageSearchResultsGroupedByDocument",
      "waitingForSearch"
    ]),
    isEveryMatchSelected() {
      return this.searchPDFResults.every(doc =>
        doc.every(pageResult =>
          pageResult.page_matches.every(match => match.selected)
        )
      );
    },
    selectAllButtonText() {
      return this.isEveryMatchSelected ? 'Fravælg alle' : 'Vælg alle';
    },
    isAResultChecked() {
      return !this.searchPDFResults.some(doc =>
        doc.some(pageResult =>
          pageResult.page_matches.some(match => match.selected)
        )
      );
    },
  },
  watch: {
    'searchPDFResults': {
      handler(newVal) {
        console.log(newVal);
        newVal.forEach(doc => {
          doc.forEach(pageResult => {
            pageResult.page_matches.forEach(pageMatch => {
              this.$watch(() => pageMatch.selected, (newVal) => {
                if (newVal && this.redactionExists(pageMatch, pageResult.page_number, pageResult.document_id)) {
                  pageMatch.selected = false;
                }
              });
            });
          });
        });
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (this.openFiles.length === 0) {
        await this.$store.dispatch("loadFilesFromApi", this.openProcess.processId);
      }
    this.openDocument = this.openFile;
  },
  methods: {
    async goToResult(search, docId, pageNumber) {
      const dataSearch = this.transformData(search, pageNumber)
      // Set redaction element to scroll to
      this.$store.commit("addRedactionToScrollTo", dataSearch)
    },
    goToSearchLocation(searchResult) {
      const event = new CustomEvent("scrollToSearch", {
        detail: searchResult,
      });
      window.document
        .querySelector("#pdfViewerIFrame")
        // @ts-ignore
        .contentDocument.dispatchEvent(event);
    },
    transformData(pageData, pageNumber) {
      return {
        type: "search",
        comment: pageData.display_name,
        guid: pageData.document_id,
        page_number: pageNumber,
        id: pageNumber,
        positions: (pageData.page_matches || []).map(matchItem => {
          return (matchItem.match || []).map(matchData => {
            return {
              height: (matchData.bottom * 1.33227731) - (matchData.top * 1.33227731),
              id: matchData.match_id.toString(),
              oldY: 0, // Placeholder value
              page_scale: 4 / 3,
              width: (matchData.x1 * 1.33227731) - (matchData.x0 * 1.33227731),
              x: matchData.x0 * 1.33227731,
              y: matchData.top * 1.33227731
            };
          });
        }).reduce((acc, val) => acc.concat(val), []) // Flatten the array
      };
    },
    togglePageSelection(docSearchIndex, pageIndex) {
      let pageResult = this.searchPDFResults[docSearchIndex][pageIndex];
      let isSelected = this.areAllSelected(pageResult.page_matches);
      // Toggle the selection for all matches on this page
      pageResult.page_matches.forEach((match) => {
      if (!match.alreadyRedacted) {
        match.selected = !isSelected;
      }
    });
    },
    toggleAllPagesSelection() {
      const shouldSelectAll = !this.isEveryMatchSelected;
      this.searchPDFResults.forEach(doc => {
        doc.forEach(pageResult => {
          pageResult.page_matches.forEach((match) => {
            if (!match.alreadyRedacted) {
              match.selected = shouldSelectAll;
            }
          });
        });
      });
    },
    areAllSelected(matches) {
      // Checks if all matches in a page are selected
      return matches.every(match => match.selected);
    },
    flattenItems() {
      return this.pages.reduce((acc, page) => [...acc, ...page.items], []);
    },
    lawAndRedactResults() {
      // First, show the law selection modal
      this.setShowTools();
    },
    setShowTools() {
      // disable scrolling in the background by hiding overflow on body
      if (this.openProcess.law_set.name === "INS") {
        this.redactSearchResults();
      } else {
        document.body.classList.add("modal-open");
        this.$modal.show(
          LawSelection,
          {
            element: {},
            update: this.update,
            shouldEmit: true,
            elementType: "search",
            documentId: this.openFile.id, 
            processId: this.$route.params.processId
          },
          {
            classes: 'overflow-visible',
            height: "700px",
            width: "60%",
            clickToClose: true,
          },
          {
            "before-close": this.enableScrollAgain,
          }
        );
      }
    },
    enableScrollAgain() {
      document.body.classList.remove("modal-open");
    },
    async update(paragraph) {
      // console.log("Updating search law");
      this.selectedLaw = paragraph.law;
      await this.redactSearchResults();
    },
    async redactSearchResults() {
      const redactionsToSave = this.searchPDFResults.flatMap(doc =>
        doc.flatMap(pageResult =>
          pageResult.page_matches.filter(pageMatch => pageMatch.selected && !pageMatch.alreadyRedacted)
          .map(pageMatch => {
              const matchPositions = pageMatch.match.map(matchResult => ({
                x: (matchResult.x0 * (4 / 3)) - 1.2,
                y: (matchResult.top * (4 / 3)) - 3,
                width: (matchResult.x1 * (4 / 3)) - (matchResult.x0 * (4 / 3)) + 2.4,
                height: (matchResult.bottom * (4 / 3)) - (matchResult.top * (4 / 3)) + 4,
                page_scale: 4 / 3,
                id: uuidv4(),
              }));
              return {
                documentId: pageResult.document_id,
                processId: this.openFile.process.process_id,
                data: {
                  document: this.openFile.id,
                  page_number: Number(pageResult.page_number),
                  redacted_text: pageMatch.match.map(m => m.text).join(" "),
                  redaction_type: "searched",
                  redaction_id: uuidv4(),
                  law: { id: this.selectedLaw.id || 0 },
                  positions: matchPositions,
                }
              };
            })
        )
      ).filter(redaction => !this.redactions.some(r =>
        r.page_number === redaction.data.page_number &&
        r.redacted_text === redaction.data.redacted_text &&
        r.document.id === redaction.data.document &&
        r.positions.every((pos, index) =>
          pos.x === redaction.data.positions[index]?.x &&
          pos.y === redaction.data.positions[index]?.y
        )
      ));
      
      if (redactionsToSave.length > 0) {
        this.$store.dispatch("posthogCapture", { event_name: "RedactSearchResults", data: {} });
        await this.$store.dispatch("pushRedaction", redactionsToSave);
        await this.$store.dispatch("setSearchTerm", "");
        await this.$store.commit("setDocumentViewerNav", "redactions");
        console.log("attempting to reload redactions");
        await this.sendRedactions()
      }
    },
    async sendRedactions() {
      if (!this.openDocument || !this.openDocument.id) {
        console.error('openDocument or openDocument.id is undefined (DocumentViewer - sendRedactions: ca line 940)');
        return;
      }
      
      let redactionForDocument = [];
      this.redactions.forEach((redaction, index) => {
        if (redaction && redaction.document && redaction.document.id === this.openDocument.id) {
          redactionForDocument.push({
            ...redaction,
            number: index + 1
          });
        }
      });
      const iframe = window.document.querySelector('#pdfViewerIFrame');
      if (!iframe || !iframe.contentDocument) {
        console.error('pdfViewerIFrame or its contentDocument is null');
        return;
      }
      
      const event = new CustomEvent('setRedactions', { detail: redactionForDocument });
      iframe.contentDocument.dispatchEvent(event);
    },
    redactionExists(searchResult, pageNumber, documentId) {
      // console.log("searched === '"+ this.redactions[0].redaction_type+"'")
      // console.log("PageNumbers:", this.redactions[0].page_number, pageNumber);
      // console.log("RedactedText: '"+ this.redactions[0].redacted_text + "' === '" + searchResult.match.map(m => m.text).join(" ") + "'");
      // console.log("DocumentId:", this.redactions[0].document.document_id, documentId)
      // // console.log(searchResult)
      // if (searchResult.match.length > 1) {
      //   this.redactions[0].positions.map((pos, index) => {
      //     console.log("Positions X", pos.x, ((searchResult.match[index].x0 * (4 / 3)) - 1.2))
      //     console.log("Positions Y", pos.y, ((searchResult.match[index].top * (4 / 3)) - 3))
      //   });
      // }

      return this.redactions.some(r =>
        r.redaction_type === "searched" &&
        Number(r.page_number) === Number(pageNumber) &&
        r.redacted_text === searchResult.match.map(m => m.text).join(" ") &&
        r.document.document_id === documentId &&
        r.positions.every((pos, index) =>
          pos.x === Number((searchResult.match[index].x0 * (4 / 3)) - 1.2) &&
          pos.y === Number((searchResult.match[index].top * (4 / 3)) - 3)
        )
      );
    },
  },
};
</script>

<style>
.break-words {
    word-break: break-all;
}
</style>