<template>
  <redaction-button-exit
    class="absolute right-0 top-0 m-2"
    @emit-close="closeModal()"
  />
  <div class="video-example">
    <!-- Video Title -->
    <h3 v-if="title" class="video-title">{{ title }}</h3>

    <!-- Video Player -->
    <div class="video-container">
      <video
        ref="videoPlayer"
        class="video-player"
        playsinline
        controls
        :poster="resolveAssetPath(thumbnail)"
        @playing="handlePlaying"
        @error="handleError"
      >
        <source
          :src="resolveAssetPath(src)"
          type="video/mp4"
        >
        <p class="fallback-text">
          Your browser doesn't support HTML5 video. Here is a
          <a :href="resolveAssetPath(src)" class="fallback-link">link to the video</a> instead.
        </p>
      </video>

      <!-- Loading State -->
      <div 
        v-if="loading" 
        class="overlay loading-overlay"
      >
        <div class="loading-spinner"></div>
        <div class="loading-text">Indlæser video...</div>
      </div>

      <!-- Error State -->
      <div 
        v-if="error" 
        class="overlay error-overlay"
      >
        <div class="error-message">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonExit from "@/components/controls/buttonExit.vue";
export default {
  name: 'VideoExample',
  components: {
    "redaction-button-exit": buttonExit,
  },
  props: {
    // Video title
    title: {
      type: String,
      default: ''
    },
    // Video source URL
    src: {
      type: String,
      required: true
    },
    // Video thumbnail/poster image
    thumbnail: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: true,
      error: null
    }
  },

  methods: {
    // Handle video playing event
    handlePlaying() {
      console.log("handleplaying");
      this.loading = false;
      this.$emit('playing', {
        title: this.title,
        src: this.src
      });
    },
    closeModal() {
        this.$emit("close");
    },
    // Handle video error
    handleError(e) {
      console.log("error");
      this.loading = false;
      this.error = 'Error loading video. Please try again later.';
      console.error('Video error:', e);
      this.$emit('error', e);
    },

    // Public method to play video
    play() {
      console.log("play");
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.play();
      }
    },

    // Public method to pause video
    pause() {
      console.log("pause");
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
      }
    },

    // Public method to reset video
    reset() {
      console.log("reset");
      
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
        this.error = null;
        this.loading = true;
      }
    },

    // Helper method to resolve asset paths
    resolveAssetPath(path) {
      if (!path) return '';
      // If it's already an absolute URL or data URL, return as is
      if (path.startsWith('http') || path.startsWith('data:') || path.startsWith('blob:')) {
        return path;
      }

      // Remove any leading dots or slashes
      const cleanPath = path.replace(/^\.\.\//, '');
      
      try {
        // Try to require the asset
        this.loading = false;
        return require(`@/assets/${cleanPath}`);
      } catch (e) {
        console.warn(`Could not load asset: ${path}`, e);
        return path;
      }
    }
  }
}
</script>

<style scoped>
.video-example {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.video-example:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.video-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
  transition: color 0.2s ease;
}

.video-title:hover {
  color: #2d3748;
}

.video-container {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-color: #181818;
}

.video-player {
  display: block;
  width: 100%;
  max-height: 70vh;
  outline: none;
  background-color: #181818;
}

/* Video Controls Styling */
.video-player::-webkit-media-controls {
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 0 0 0.5rem 0.5rem;
}

.video-player::-webkit-media-controls-panel {
  background-color: rgba(0, 0, 0, 0.0);
}

.video-player::-webkit-media-controls-play-button {
  border-radius: 50%;
  transition: all 0.2s ease;
}

.video-player::-webkit-media-controls-timeline {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  height: 4px;
}

.video-player::-webkit-media-controls-volume-slider {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  padding: 0 5px;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.loading-overlay {
  color: white;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loading-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.error-overlay {
  background-color: rgba(220, 38, 38, 0.9);
}

.error-message {
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: white;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fallback-text {
  padding: 1rem;
  color: white;
  text-align: center;
}

.fallback-link {
  color: #60a5fa;
  text-decoration: underline;
  transition: color 0.2s ease;
}

.fallback-link:hover {
  color: #93c5fd;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 640px) {
  .video-example {
    padding: 0.5rem;
  }

  .video-title {
    font-size: 1.25rem;
  }

  .loading-spinner {
    width: 30px;
    height: 30px;
  }

  .loading-text {
    font-size: 1rem;
  }
}
</style>
