<template>
  <div
    id="html-editor"
    class="divide-y divide-gray-400 rounded-md border border-gray-400 overflow-hidden"
  >
    <div>
      <vue-file-toolbar-menu 
        :content="menu" 
        class="bar"
      />
    </div>
    <div 
      class="flex flex-col editor-content-container"
      style="font-family: Georgia, sans-serif"
    >
      <vue-document-editor
        ref="editor"
        v-model:content="docContent"
        class="editor" 
        :overlay="overlay"
        :zoom="zoom"
        :page_format_mm="page_format_mm"
        :page_margins="page_margins"
        :display="display"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ref, watch, onMounted } from "vue";
import VueFileToolbarMenu from 'vue-file-toolbar-menu'
import VueDocumentEditor from 'vue-document-editor'
import ConfirmResetDecisionLetterContent from "@/components/forms/ConfirmResetDecisionLetterContent.vue";
import { integer } from '@vuelidate/validators';
import axios from "axios";
import { debounce } from "lodash";

import {
  ArrowBackUpIcon,
  ArrowForwardUpIcon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  PaintIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignCenterIcon,
  AlignJustifiedIcon,
  LetterPIcon,
  LetterSIcon,
} from "vue-tabler-icons";

export default {
  components: {
    VueFileToolbarMenu,
    VueDocumentEditor
  },
  props: {
    content: {
      type: Object,
    },
    decisionLetterId: integer,
    logo: {
      type: Object,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    //const margins = props.logo.name.split(".")[0] === "Fredensborg" ? "10mm 15mm" : "20mm 15mm";
    const computePageMargins = (pageIndex) => {
      if (props.logo.name.split(".")[0] !== "Fredensborg") {
        return "20mm 15mm"
      }
      // return pageIndex === 1 ? "0mm 15mm" : "10mm 15mm";
      return pageIndex === 1 ? "0mm 10mm 20mm 20mm" : "10mm 10mm 20mm 20mm";
    }

    const page_format_mm = ref([210, 297]); // s_new [212, 297]         // Should be [210, 297]
    const page_margins = ref(computePageMargins);
    //const page_margins = ref("20mm 15mm"); // old assens - "10mm 15mm"  // should be "20mm"
    const zoom = ref(0.8);
    const zoom_min = ref(0.10);
    const zoom_max = ref(5.0);
    const theme = ref("default");
    const display = ref("vertical"); // ["grid", "vertical", "horizontal"]
    const font = ref("Georgia");
    const fontSize = ref("11pt");

    return {
      page_format_mm,
      page_margins,
      zoom,
      zoom_min,
      zoom_max,
      theme,
      display,
      font,
      fontSize,
    };
  },
  data() {
    return {
      html: '',
      json: '',
      heading: { label: "Overskrifter" },
      edit_mode: true,
      mounted: false, // will be true after this component is mounted
      undo_count: -1, // contains the number of times user can undo (= current position in content_history)
      content_history: [], // contains the content states for undo/redo operations
      docContent: this.content && this.content.htmlContent ? this.content.htmlContent : "", // content for every '' it starts a new page
      numRows: 1,
      numCols: 1,
      totalPageNumber: 0,
    }
  },
  computed: {
    ...mapState([
      "apiUrl",
    ]),
    menu () {
      return [
        // { text: "Print", title: "Print", icon: "print", click: () => window.print() },

        // { is: "spacer" },
        // { is: "separator" },

        // Undo / redo commands
        { title: "Fortryd", icon: "undo", disabled: !this.can_undo, hotkey: "ctrl+z", click: () => this.undo() },
        { title: "Gentag", icon: "redo", disabled: !this.can_redo, hotkey: "ctrl+y", click: () => this.redo() },

        { is: "separator" },

        // Rich text menus
        { icon: "format_align_left", title: "Juster venstre", active: this.isLeftAligned, disabled: !this.current_text_style, hotkey: "ctrl+shift+l", click: () => document.execCommand("justifyLeft") },
        { icon: "format_align_center", title: "Juster centrer", active: this.isCentered, disabled: !this.current_text_style, hotkey: "ctrl+shift+e", click: () => document.execCommand("justifyCenter") },
        { icon: "format_align_right", title: "Juster højre", active: this.isRightAligned, disabled: !this.current_text_style, hotkey: "ctrl+shift+r", click: () => document.execCommand("justifyRight") },
        { icon: "format_align_justify", title: "Juster indhold (lige margener)", active: this.isJustified, disabled: !this.current_text_style, hotkey: "ctrl+shift+j", click: () => document.execCommand("justifyFull") },
        { is: "separator" },
        {
          text: this.fontSize.split("pt")[0],
          title: "Skriftstørrelse",
          icon: "text_fields",
          menu: this.fontSizeMenu,
          disabled: !this.current_text_style,
          menu_height: 200
        },
        { icon: "format_bold", title: "Fed skrift (Bold)", active: this.isBold, disabled: !this.current_text_style, hotkey: "ctrl+b", click: () => document.execCommand("bold") },
        { icon: "format_italic", title: "Kursiv (Italic)", active: this.isItalic, disabled: !this.current_text_style, hotkey: "ctrl+i", click: () => document.execCommand("italic") },
        { icon: "format_underline", title: "Understreget", active: this.isUnderline, disabled: !this.current_text_style, hotkey: "ctrl+u", click: () => document.execCommand("underline") },
        { icon: "format_strikethrough", title: "Gennemstreget", active: this.isStrikeThrough, disabled: !this.current_text_style, click: () => document.execCommand("strikethrough") },
        { is: "button-color", type: "compact", menu_class: "align-center", disabled: !this.current_text_style, color: this.curColor, update_color: (new_color) => document.execCommand('foreColor', false, new_color.hex8) },
        { is: "separator" },
        { icon: "format_list_numbered", title: "Nummereret liste", active: this.isNumberedList, disabled: !this.current_text_style, click: () => document.execCommand("insertOrderedList") },
        { icon: "format_list_bulleted", title: "Punktopstillet liste", active: this.isBulletedList, disabled: !this.current_text_style, click: () => document.execCommand("insertUnorderedList") },
        { icon: "format_clear", title: "Fjern formatering", disabled: !this.current_text_style, click () { document.execCommand('removeFormat'); document.execCommand('formatBlock', false, '<div>'); } },
        { is: "separator" },
        {
          html: '<div class="ellipsis" style="width: 80px; font-size: 95%;">'+this.font+'</div>',
          title: "Skrifttyper",
          chevron: true,
          menu: this.font_menu,
          disabled: !this.current_text_style,
          menu_height: 200
        },
        {
          html: '<div class="ellipsis" style="width: 80px; font-size: 95%;">'+this.heading.label+'</div>',
          title: "Overskrifter",
          chevron: true,
          menu: this.heading_menu,
          disabled: !this.current_text_style,
          menu_height: 200
        },
        { is: "separator" },
        { // Zoom menu
          text: Math.floor(this.zoom * 100) + "%",
          title: "Zoom",
          icon: "zoom_in",
          chevron: true,
          menu: [
            ["200%", 2.0],
            ["150%", 1.5],
            ["125%", 1.25],
            ["100%", 1.0],
            ["75%", 0.75],
            ["50%", 0.5],
            ["25%", 0.25]
          ].map(([text, zoom]) => {
            return {
              text,
              active: this.zoom == zoom,
              click: () => { this.zoom = zoom; }
            }
          }),
          disabled: !this.current_text_style,
          menu_width: 80,
          menu_height: 200,
          menu_class: "align-center"
        },
        { is: "separator" },
        { icon: 'restore', title: "Gendan/reset Afgørelsesbrev", click: () => this.confirmResetDecisionLetterContent() },
        /* { is: "separator" },
        {
          icon: "view_column",
          title: "Indsæt spalter",
          click: () => this.insertColumns()
        } */
      ]
    },
    heading_menu () {
      return this.headers.map(heading => {
        let scaledFontSize = parseInt(heading.fontSize) * 0.8 + 'px';
        return {
          html: `<b style="width: 130px; font-size: ${scaledFontSize};">${heading.label}</b>`,
          title: heading.label,
          active: this[`is${heading.tag}`],
          disabled: !this.current_text_style,
          height: 30,
          click: () => {
            document.execCommand('formatBlock', false, `<${heading.tag.toLowerCase()}>`)
            this.heading.label = heading.label;
            this.updateActiveHeaderStyle(heading);
          }
        };
      });
    },
    headers() {
      return [
        { tag: 'H1', label: 'Overskrift 1', fontSize: '28px', fontWeight: 'bold', color: '#333' },
        { tag: 'H2', label: 'Overskrift 2', fontSize: '24px', fontWeight: 'bold', color: '#555' },
        { tag: 'H3', label: 'Overskrift 3', fontSize: '20px', fontWeight: 'bold', color: '#777' },
        { tag: 'H4', label: 'Overskrift 4', fontSize: '18px', fontWeight: 'bold', color: '#999' },
        { tag: 'H5', label: 'Overskrift 5', fontSize: '16px', fontWeight: 'bold', color: '#BBB' },
        { tag: 'H6', label: 'Overskrift 6', fontSize: '14px', fontWeight: 'bold', color: '#DDD' },
        { tag: 'P', label: 'Normal', fontSize: '16px', fontWeight: 'normal', color: '#000' }
      ];
    },
    isH1 () { return this.current_text_style.headerLevel == 1; },
    isH2 () { return this.current_text_style.headerLevel == 2; },
    isH3 () { return this.current_text_style.headerLevel == 3; },
    isH4 () { return this.current_text_style.headerLevel == 4; },
    isH5 () { return this.current_text_style.headerLevel == 5; },
    isH6 () { return this.current_text_style.headerLevel == 6; },
    isP () { return this.current_text_style.headerLevel == 0; },
    font_menu () {
      return this.font_list.map(font => {
        return {
          html: '<span class="ellipsis" style="font-family:'+font+'">'+font+'</span>',
          icon: (this.theme != "default" && this.font == font) ? 'check' : false,
          active: (this.font == font),
          height: 30,
          click: () => {
            document.execCommand("fontName", false, font);
            this.font = font;
          }
        };
      });
    },
    font_list: () => ["Arial", "Avenir", "Courier New", "Garamond", "Georgia", "Impact", "Helvetica", "Palatino", "Roboto", "Times New Roman", "Verdana"],
    fontSizeMenu() {
      const sizes = ['8pt', '9pt', '10pt', '11pt', '12pt', '14pt', '16pt', '18pt', '20pt', '24pt', '28pt', '32pt'];
      return sizes.map(size => ({
        text: size.split("pt")[0],
        active: this.fontSize === size,
        click: () => this.setFontSize(size)
      }));
    },
    // Current text style management
    current_text_style () { return this.mounted ? this.$refs.editor.current_text_style : false; },
    isLeftAligned () { return ["start", "left", "-moz-left"].includes(this.current_text_style.textAlign); },
    isRightAligned () { return ["end", "right", "-moz-right"].includes(this.current_text_style.textAlign); },
    isCentered () { return ["center", "-moz-center"].includes(this.current_text_style.textAlign); },
    isJustified () { return ["justify", "justify-all"].includes(this.current_text_style.textAlign); },
    isBold () {
      const fontWeight = this.current_text_style.fontWeight;
      return fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0);
    },
    isItalic () { return this.current_text_style.fontStyle == "italic"; },
    isUnderline () { // text-decoration is not overridden by children, so we query the parent stack
      const stack = this.current_text_style.textDecorationStack;
      return stack && stack.some(d => (d.indexOf("underline") == 0));
    },
    isStrikeThrough () { // text-decoration is not overridden by children, so we query the parent stack
      const stack = this.current_text_style.textDecorationStack;
      return stack && stack.some(d => (d.indexOf("line-through") == 0));
    },
    isNumberedList () { return this.current_text_style.isList && this.current_text_style.listStyleType == "decimal"; },
    isBulletedList () { return this.current_text_style.isList && ["disc", "circle"].includes(this.current_text_style.listStyleType); },
    curColor () { return this.current_text_style.color || "transparent"; },
    // Undo / redo flags
    can_undo () { return this.undo_count > 0; },
    can_redo () { return this.content_history.length - this.undo_count - 1 > 0; }
  },
  watch: {
    /* content: {
      immediate: true,
      handler(newContent) {
        // console.log("Test", newContent)
        // this.docContent = newContent
        if (this.editor) {
          this.editor.commands.setContent(newContent);
        } else {
          this.initializeEditor(newContent);
        }
      }
    }, */
    docContent: {
      immediate: true,
      // Fill undo / redo history stack on user input
      handler (new_content) {
        if(!this._mute_next_content_watcher) { // only update the stack when content is changed by user input, not undo/redo commands
          this.content_history[++this.undo_count] = new_content;
          this.content_history.length = this.undo_count + 1; // remove all redo items
        }
        this.debouncedUpdateTOC();
        this.$emit('update', new_content, this.content.header, this.content.footer);
        this._mute_next_content_watcher = false;
      }
    }
  },
  created() {
    // Manage history undo/redo events
    const manage_undo_redo = (e) => {
      switch(e && e.inputType){
        case "historyUndo": e.preventDefault(); e.stopPropagation(); this.undo(); break;
        case "historyRedo": e.preventDefault(); e.stopPropagation(); this.redo(); break;
      }
    }
    window.addEventListener("beforeinput", manage_undo_redo);
    window.addEventListener("input", manage_undo_redo); // in case of beforeinput event is not implemented (Firefox)
    window.addEventListener('mouseup', this.updateFont);
  },
  mounted() {
    if (!this.docContent) {
      this.docContent = this.content && this.content.htmlContent ? this.content.htmlContent : "";
    }
    if (this.logo) {
      this.getDecisionLetterLogo();
      this.updateDocContent();
    }
    this.mounted = true;
  },
  beforeUnmount() {
    window.removeEventListener('mouseup', this.updateFont);
  },
  methods: {
    async getDecisionLetterLogo() {
      const file_obj = {
        apiURL: `${this.apiUrl}/file/get_decision_letter_logo`,
        file: this.logo,
        process_id: this.$route.params.processId
      };
      const response = await this.fetchFileData(file_obj);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'image/png' });
        const blobURL = URL.createObjectURL(blob);
        const decisionLetterLogoImg = document.getElementById("decisionLetterLogo")
        if (decisionLetterLogoImg) {
          decisionLetterLogoImg.src = blobURL;
        }
      }
    }, 
    fetchFileData(file_obj) {
      const jwt_token = localStorage.getItem('aktio-ai-user-token');
      const axios_headers = { 'Authorization': 'Bearer ' + jwt_token };
      return axios({
        url: file_obj.apiURL,
        method: 'POST',
        data: file_obj,
        responseType: 'blob',
        headers: axios_headers,
      });
    },
    overlay (page, total) {
      // Add page numbers on each page
      // let html = '<div style="position: absolute; bottom: 5mm; right: 10mm; font-family: "Georgia", serif; font-size: 11pt;">Page ' + page + ' of ' + total + '</div>';
      let html = "";
      this.totalPageNumber = total
      // header all pages
      if (this.content.header && this.content.header !== "") {
        html += this.content.header;
      }
      // Footer all pages
      const isFredensborg = this.logo.name.split(".")[0] === "Fredensborg";
      if (this.content.footer && this.content.footer !== "") {
        if (!isFredensborg || (isFredensborg && page === 1)) {
          html += this.content.footer;
        }
      }
      if (isFredensborg && page !== 1) {
        html += `
          <footer>
            <div style="position: absolute; left: 10mm; right: 10mm; bottom: 10mm; text-align:right; font-family: 'Verdana', sans-serif; font-size: 9pt;">
              <p style="margin: 0;">Side ${page} af ${total}</p>
            </div>
          </footer>`;
      }
      return html;
    },
    debouncedUpdateTOC: debounce(function() {
      this.updateTOCPageNumbers();
    }, 300),
    updateTOCPageNumbers() {
      // Select all TOC rows and update page numbers
      const tocRows = document.querySelectorAll('[id^="toc-row-"]');
      let cumulativePageNumber = this.totalPageNumber + 1;

      tocRows.forEach((row) => {
        const pageNumberCell = row.querySelector('[id^="toc-pagenumbers-"] .doc-page-nr');
        const pageNumbers = pageNumberCell.textContent.split('-');

        const startPage = parseInt(pageNumbers[0], 10);
        const endPage = pageNumbers.length === 2 ? parseInt(pageNumbers[1], 10) : null;

        if (!isNaN(startPage)) {
          if (startPage !== 1) {
            if (endPage !== null && !isNaN(endPage)) {
              const newStartPage = cumulativePageNumber;
              const newEndPage = cumulativePageNumber + (endPage - startPage);
              pageNumberCell.textContent = `${newStartPage}-${newEndPage}`;
              cumulativePageNumber = newEndPage + 1;
            } else {
              const newPageNumber = cumulativePageNumber;
              pageNumberCell.textContent = `${newPageNumber}`;
              cumulativePageNumber = newPageNumber + 1;
            }
          } else {
            return;
          }
        }
      });
    },
    /* insertColumns() {
      const columnsHTML = '<div style="column-count: 2;"><div>Spalte 1</div><div>Spalte 2</div></div>';
      document.execCommand('insertHTML', false, columnsHTML);
    }, */
    // Undo / redo functions examples
    undo () { if(this.can_undo){ this._mute_next_content_watcher = true; this.docContent = this.content_history[--this.undo_count]; } },
    redo () { if(this.can_redo){ this._mute_next_content_watcher = true; this.docContent = this.content_history[++this.undo_count]; } },
    resetContentHistory () { this.content_history = []; this.undo_count = -1; },
    setFontSize(size) {
      const pxSize = this.ptToPx(size);
      const selection = window.getSelection();

      if (selection.rangeCount) {
        const range = selection.getRangeAt(0);
        if (!range.collapsed) {
          let baseNode = range.startContainer;

          // Hvis baseNode er et tekstnode, brug dens forælder
          if (baseNode.nodeType === Node.TEXT_NODE) {
            baseNode = baseNode.parentNode;
          }

          const entireNodeSelected = range.toString() === baseNode.textContent;

          // Hvis hele noden er valgt og det er et span med kun font-size, opdater denne
          if (entireNodeSelected && baseNode.tagName === 'SPAN' && baseNode.style.fontSize && !baseNode.style.cssText.replace(/font-size:\s*\d+px;/i, '').trim()) {
            // Fjern alle indre spans med kun font-size og opdater den overordnede node
            Array.from(baseNode.querySelectorAll('span')).forEach(innerSpan => {
              if (innerSpan.style.fontSize && !innerSpan.style.cssText.replace(/font-size:\s*\d+px;/i, '').trim()) {
                while (innerSpan.firstChild) {
                  innerSpan.parentNode.insertBefore(innerSpan.firstChild, innerSpan);
                }
                innerSpan.remove();
              }
            });
            baseNode.style.fontSize = pxSize;
          } else {
            // Ekstraher indhold og opret et nyt span
            const selectedContents = range.extractContents();
            const span = document.createElement('span');
            span.style.fontSize = pxSize;

            Array.from(selectedContents.querySelectorAll('span')).forEach(innerSpan => {
              while (innerSpan.firstChild) {
                innerSpan.parentNode.insertBefore(innerSpan.firstChild, innerSpan);
              }
              innerSpan.remove();
            });

            span.appendChild(selectedContents);
            range.insertNode(span);

            // Ryd og genopret selection
            const newRange = document.createRange();
            newRange.selectNodeContents(span);
            newRange.collapse(false);
            selection.removeAllRanges();
            selection.addRange(newRange);
          }

          this.updateFont();
          this.updateDocContent();
        }
      }
    },
    updateDocContent() {
      const contentUpdates = {};

      // Saml opdateringer baseret på content_idx
      this.$refs.editor.pages.forEach(page => {
        const currentContent = page.elt.innerHTML.trim();
        const previousContent = page.prev_innerHTML.trim();
        const effectiveContent = currentContent !== previousContent ? currentContent : previousContent;

        // Akkumuler indholdet for hver content_idx
        if (contentUpdates.hasOwnProperty(page.content_idx)) {
          contentUpdates[page.content_idx] += effectiveContent;  // Tilføj indholdet til den eksisterende streng
        } else {
          contentUpdates[page.content_idx] = effectiveContent;  // Start en ny streng for denne content_idx
        }
      });

      // Byg den opdaterede docContent baseret på akkumuleret indhold for hver content_idx
      const updatedDocContent = Object.keys(contentUpdates).sort().map(key => contentUpdates[key]);

      // Opdater docContent i Vue data og udsend en event med den opdaterede værdi
      this.docContent = updatedDocContent;
      // this.$emit('content-updated', this.docContent);
    },
    ptToPx(pt) {
      const points = parseInt(pt, 10);  // Make sure pt is a number
      const pixels = Math.round(points * 1.333);  // Convert points to pixels
      return pixels + 'px';
    },
    pxToPt(px) {
      const numericValue = parseFloat(px);  // remove 'px' and convert to float
      return numericValue ? `${Math.round(numericValue * 0.75)}pt` : '12pt';  // Fallback if NaN
    },
    updateActiveHeaderStyle(heading) {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      let element = range.commonAncestorContainer;

      // Ensure we are starting with an element node
      if (element.nodeType === Node.TEXT_NODE) {
        element = element.parentNode;
      }

      // Locate the closest block element or the specific tag
      while (element && !['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'].includes(element.tagName) && element !== this.$refs.editor) {
        element = element.parentNode;
      }

      if (element && ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'].includes(element.tagName)) {
        element.style.fontSize = heading.fontSize;
        element.style.fontWeight = heading.fontWeight;
        element.style.color = heading.color;
      }

      // Reset selection to reflect changes
      selection.removeAllRanges();
      selection.addRange(range);
    },
    updateFont() {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const selectedElem = selection.getRangeAt(0).commonAncestorContainer;
      const parentElement = selectedElem.nodeType === 3 ? selectedElem.parentNode : selectedElem;
      if (parentElement.closest('.editor-container')) {
        // font size
        const fontSizePx = window.getComputedStyle(parentElement).fontSize;
        this.fontSize = this.pxToPt(fontSizePx);
        // font type
        const font = window.getComputedStyle(parentElement).fontFamily;
        this.font = font.split(',')[0].trim();
        // font heading
        // Define the maximum levels up in the DOM tree we are willing to check
        const maxLevelsUp = 3;
        let level = 0;
        let parentEl = parentElement;
        let currentHeading;
        
        // Ascend up the DOM tree, checking up to a maximum of 'maxLevelsUp' ancestors for a heading
        while (parentEl && level < maxLevelsUp && parentEl.nodeType === Node.ELEMENT_NODE && !parentEl.tagName.startsWith('H')) {
          parentEl = parentEl.parentNode;
          level++;
        }
        if (parentEl && parentEl.nodeType === Node.ELEMENT_NODE && parentEl.tagName.startsWith('H')) {
          currentHeading = this.headers.find(h => h.tag === parentEl.tagName);
        } else {
          // Default to paragraph or other checks without looping
          currentHeading = this.headers.find(h => h.tag === parentElement.tagName);
        }
        if (currentHeading) {
          this.heading.label = currentHeading.label;
        } else {
          // Reset or update heading label based on other default or desired behavior
          this.heading.label = 'Overskrifter'; // Neutral default
        }
      }
    },
    confirmResetDecisionLetterContent() {
      this.$modal.show(
        ConfirmResetDecisionLetterContent,
        {
          processId: this.$route.params.processId,
          decisionLetterId: this.decisionLetterId,
        });
      this.$store.dispatch("posthogCapture", {event_name:"ResetDecisionLetterContent", data: {}});
    },
  },
}
</script>

<style>
.editor-container {
  --viewer-container-height: initial; /* Revert to default value */
  --pdfViewer-padding-bottom: initial;
  --page-margin: initial;
  --page-border: initial;
  --spreadHorizontalWrapped-margin-LR: initial;
  --loading-icon-delay: initial;
}

.editor-content-container div,
.editor-content-container p,
.editor-content-container span {
  word-break: normal !important;
  white-space: normal !important;
  overflow-wrap: normal !important;
  /* line-height: 1.25; */
}

.editor-content-container p {
  margin: 0 !important;
}

.editor-content-container {
  max-height: 800px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

.editor-content-container::-webkit-scrollbar {
  width: 12px;
}

.editor-content-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 0.375rem;
}

.editor-content-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 0.375rem;
  border: 3px solid #f0f0f0;
}

.bar {
  background-color: #fff;
}

.bar-menu-items {
  min-width: 80px !important;
}

.bar-menu-item {
  border-bottom: 1px solid lightgray;
}

.editor-content-container ol {
  list-style-type: decimal;
  margin: 0;
  padding-left: 50px;
}

.editor-content-container ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 75px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.editor-content-container footer div {
  margin-bottom: 5px;
}

@media only screen and (max-width: 1023px) {
  .overlay:after {
    background: rgba(0, 0, 0, 0);
  }
}
</style>