<template>
  <div id="help">
    <div class="grid grid-cols-3 gap-4 bg-white mt-2">
      <div class="col-span-3 border-b">
        <accordion-item
          title="Hjælp til Aktio Indsigt"
          :open="true"
        >
          <p class="mb-2">
            Her kan du finde adskillige emner og videoer til at guide dig igennem aktio indsigt 
          </p>
          <p class="mt-2">
            Har du brug for yderligere hjælp eller har du ris eller ros (vi lytter meget gerne), kan du kontakte <strong><a href="mailto:support@aktio.ai">support@aktio.ai</a></strong>, husk du også kan spørge en kollega om hjælp. Vores svartid er typisk ~1 arbejdsdag. NB: Hvis du sender support e-mails til os, bør du undlade at medsende personoplysninger vedrørende sager i eks. screenshots, vedhæftede filer eller lignende - send i stedet eks. sagsid.
          </p>
        </accordion-item>

        <AccordionItem
          title="Undervisningsvideoer"
        >
          <div class="font-light">
            <p class="mb-4">
              Herunder finder du forskellige videoer, som viser dig hvordan du kan bruge Aktio Indsigt, når du
              behandler en aktindsigt.
            </p>
            <p class="mb-8">
              Du kan også få indsat dine egne undervisningsvideoer til aktindsigt her - kontakt da Aktio Indsigt
              support.
            </p>
            <div id="videoItem" class="w-full">
              <div v-if="!videos.length" class="text-center py-8">
                <p>Loading videos...</p>
              </div>
              <VideoList
                v-else
                :listdata="videos"
                @clicked-item="openVideoModal"
              />
            </div>
          </div>
        </AccordionItem>

        <AccordionItem title="Oversigt over dokument statusser og deres beskrivelse">
          <div class="font-light">
            <p>
              Når der indlæses dokumenter i Aktio Indsigt, kan disse få en række forskellige statuskoder. Herunder
              kan du se disse statuskoder, og hvilken betydning de har.
            </p>
            <h2
              v-if="documentStatuses.length === 0"
              class="pt-4 font-bold"
            >
              Henter dokument statusser...
            </h2>
            <div
              v-for="(documentStatus) in sortedDocumentStatuses(documentStatuses)"
              :key="documentStatus.id"
              class="pt-4"
            >
              <div
                class="inline-block h-6 rounded text-xs font-bold text-white px-2 py-1 whitespace-no-wrap"
                :class="[
                  documentStatus.background_color_class || 'bg-gray-500'
                ]"
              >
                {{ documentStatus.display_name }}
              </div> 
              {{ documentStatus.description }} 
              <span v-if="documentStatus.background_color_class === 'bg-red-400'">
                <strong><a href="mailto:support@aktio.ai">support@aktio.ai</a></strong>
              </span>
            </div>
          </div>
        </AccordionItem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VideoList from "../components/VideoList.vue";
import VideoExample from "../components/VideoExample.vue";
import videoTutorials from "../assets/json/videoTutorials.json";
import AccordionItem from "../components/controls/AccordionItem.vue";

export default {
  name: "Help",
  components: {
    VideoList,
    AccordionItem,
  },
  data() {
    return {
      videos: videoTutorials,
      nameVideo: '',
      clickedVideo: '',
      poster: '',
      currentVideo: null,
      videoError: null
    }
  },
  computed: {
    ...mapState(["documentStatuses"]),
  },
  mounted(){
    this.$store.dispatch("getDocumentStatuses")
  },
  methods: {
    sortedDocumentStatuses(array) {
      return array.slice().sort((a,b) => a.sort_order - b.sort_order)
    },
    openVideoModal(video) {
      this.$store.dispatch("posthogCapture", {
        event_name: "OpenVideoModal", 
        data: {
          videoName: video.title, 
          videoSource: video.src
        }
      });
      
      this.currentVideo = video;
      this.nameVideo = video.title;
      this.clickedVideo = video.src;
      this.poster = video.thumbnail;
      this.videoError = null;
      
      this.$modal.show(
        VideoExample,
        {
          title: video.title,
          src: video.src,
          thumbnail: video.thumbnail,
          description: video.description,
        },
        {
          classes: "overflow-visible",
          height: "750px",
          width: "60%",
          clickToClose: true,
        }
      );
    },
    registerPlay(e) {
      this.$store.dispatch("posthogCapture", {
        event_name: "PlayVideo", 
        data: {
          VideoTitle: this.nameVideo,
          VideoSource: this.clickedVideo
        }
      });
    },
    handleVideoError(e) {
      console.error('Video error:', e);
      this.videoError = 'Error loading video. Please try again later.';
    },
    beforeModalOpen(event) {
      // Reset video state when modal opens
      this.videoError = null;
      if (this.$refs.videoExample) {
        this.$refs.videoExample.reset();
      }
    },
    beforeModalClose(event) {
      // Pause video when modal closes
      if (this.$refs.videoExample) {
        this.$refs.videoExample.pause();
      }
    }
  }
}
</script>

<style scoped>
.accordion-body p {
  word-break: keep-all;
}

/* Modal styles */
::v-deep .vm--modal {
  background-color: #000 !important;
  border-radius: 8px;
  overflow: hidden !important;
}

::v-deep .vm--overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
</style>
