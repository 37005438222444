<template>
  <div class="videoContainer py-5 grid grid-cols-3 gap-8 w-full">
    <div
      v-for="(video,index) in listdata"
      :key="index"
      class="videoItem overflow-hidden rounded-lg shadow-lg transition-all duration-300 transform hover:scale-105"
      @click="$emit('clicked-item', video)"
    >
      <div class="relative">
        <img
          class="w-full h-48 object-cover"
          :src="resolveAssetPath(video.thumbnail)"
          :alt="Hjælpevideo"
        >
        <div class="absolute inset-0 bg-black bg-opacity-20 hover:bg-opacity-10 transition-all duration-300">
          <div class="absolute inset-0 flex items-center justify-center">
            <svg class="w-16 h-16 text-white opacity-80" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 0C4.477 0 0 4.477 0 10c0 5.523 4.477 10 10 10s10-4.477 10-10C20 4.477 15.523 0 10 0zm3.5 10.5l-5 3A.5.5 0 017 13V7a.5.5 0 01.8-.4l5 3a.5.5 0 010 .9z"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="p-4 bg-white">
        <h2 class="text-xl font-semibold mb-2 text-gray-800">
          {{ video.title }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoList',
  props: {
    listdata: {
      type: Array,
      required: true,
      validator: function(value) {
        return value.every(item => {
          return item.title && 
                 item.src && 
                 item.thumbnail;
        });
      }
    },
  },
  methods: {
    // Helper method to resolve asset paths
    resolveAssetPath(path) {
      if (!path) return '';
      
      // If it's already an absolute URL or data URL, return as is
      if (path.startsWith('http') || path.startsWith('data:') || path.startsWith('blob:')) {
        return path;
      }

      // Remove any leading dots or slashes
      const cleanPath = path.replace(/^\.\.\//, '');
      
      try {
        // Try to require the asset
        return require(`@/assets/${cleanPath}`);
      } catch (e) {
        console.warn(`Could not load asset: ${path}`, e);
        return path;
      }
    }
  }
}
</script>

<style scoped>
.videoItem {
  cursor: pointer;
  background-color: white;
  border: 1px solid #e2e8f0;
}

.videoItem:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Add loading placeholder styles */
.w-full.h-48 {
  background-color: #f3f4f6;
  position: relative;
  overflow: hidden;
}

.w-full.h-48::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.4), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
