<template>
  <div class="p-8">
    <redaction-button-exit class="absolute right-0 top-0 m-2" @emit-close="closeModal('close')" />
    <h1 class="text-xl text-center">
      Journaliser dokumenter til eksisterende sag i Acadre
    </h1>
    <div v-if="acadreConnectionStatus" class="py-2">
      <div class="flex flex-col items-center">
        <label for="acadre-case-id" class="my-2 text-sm font-medium">
          Skriv et sagsnummer til en sag
        </label>
        <input 
          id="acadre-case-id" 
          v-model="case_number"
          autocomplete="off"
          class="w-40 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-center"
          type="text" placeholder="'24/001'" name="acadre-case-title" :disabled="editDisabled"
          @keyup.enter="checkCaseAvailability()">
        <standard-button class="button-check-case m-2" button-size="small" button-text="Tjek sag"
          button-icon-fa="fa-magnifying-glass" button-type="standard" @click="checkCaseAvailability()" />
        <div v-if="caseAvailability && caseAvailability.CaseFileTitleText" class="w-1/2 my-4">
          <table class="w-full border border-collapse border-gray-300 shadow-lg ">
            <thead>
              <tr>
                <th colspan="2">
                  Sag fundet fra Acadre
                </th>
              </tr>
            </thead>
            <tbody class="border">
              <tr class="border">
                <td class="border p-2">
                  Title:
                </td>
                <td class="border p-2">
                  {{ caseAvailability.CaseFileTitleText }}
                </td>
              </tr>
              <tr class="border">
                <td class="border p-2">
                  Beskrivelse:
                </td>
                <td class="border p-2">
                  {{ caseAvailability.TitleAlternativeText }}
                </td>
              </tr>
              <tr class="border">
                <td class="border p-2">
                  Sagstype:
                </td>
                <td class="border p-2">
                  {{ caseAvailability.CaseFileTypeCode }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="caseAvailability" class="w-80 text-center m-4 text-red-500">
          Sag ikke tilgængelig
        </div>
      </div>
      <div class="text-red-500 mt-4 font-medium text-sm text-center">
        {{ inputValidationMessage }}
      </div>
      <standard-button class="button-create-case" button-size="small" button-text="Journaliser til sag"
        button-icon-fa="fa-file-lines" button-type="standard" @click="sendDocumentsToAcadre()" />
      <div v-show="isLoading" class="loading-overlay">
        <redaction-button-exit class="absolute right-0 top-0 m-2" @emit-close="closeModal('close')" />
        <redaction-icon-spinner class="text-black w-40 h-40" />
      </div>
    </div>
    <div v-else class="py-2">
      <h2 class="text-center text-red-500 mt-4">
        Systemet kan ikke oprette forbindelse til Acadre
      </h2>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reactive } from 'vue';
import Multiselect from "vue-multiselect";
import StandardButton from "@/components/controls/StandardButton.vue";
import IconAsterisk from "@/components/icons/asterisk.vue";
import buttonExit from "@/components/controls/buttonExit.vue";
import iconSpinner from "@/components/icons/spinner.vue";

export default {
  name: "ModalExportExternalDocuments",
  components: {
    "redaction-button-exit": buttonExit,
    "redaction-icon-spinner": iconSpinner,
    StandardButton,
  },
  data() {
    return {
      case_number: "",
      inputValidationMessage: "",
      acadreDocumentData: {
        // document_title_text add to backend
        // description_text add to backend
        document_category_code: {}, // Literal, Dokumentkategori
        document_status_code: {}, // Literal, Dokumentstatus
        // case_file_reference: str, // Add Later
        registration_date: null, // Journal Dato
        document_date: null, // Dok. Datoer
        // publication_indicator: str, Already exists
        // case_file_manager_reference: {}, Already exists
        document_type_code: {
          Literal: "U",
          Description: "Udgående",
          AssignExternalRecipient: true,
          AssignInternalRecipient: true,
          IsExternallyProduced: false,
          RequiresFollowUp: false
        }, // Literal, 
        record_status_code: {
          Literal: "J",
          Description: "Endelig"
        }, // Literal, 
        even_out_required: false,
        // file_name add through backend
        // xml_binary add through backend
      },
      selectedIndex: null,
      editDisabled: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('acadre', [
      "acadreConnectionStatus",
      "acadreCreateCaseId",
      "caseAvailability",
      "errorMessage",
      "acadreDocumentCategoryList",
      "acadreDocumentStatusList",
    ]),
    ...mapState([
      "openProcess",
    ])
  },
  created() {

  },
  async mounted() {
    this.isLoading = true
    await this.$store.dispatch("loadUserFromAPI");
    await this.$store.dispatch("acadre/checkAcadreConnection")
    if (this.acadreConnectionStatus) {
      if (this.acadreDocumentCategoryList.length === 0) {
          await this.$store.dispatch("acadre/loadAcadreDocumentCategoryList")
      }
      this.acadreDocumentData.document_category_code = this.acadreDocumentCategoryList.find(obj => obj.Literal === "Besvarelse") || this.acadreDocumentCategoryList[0];
      // this.acadreOptions.documentCategory = this.acadreDocumentCategoryList
      
      if (this.acadreDocumentStatusList.length === 0) {
          await this.$store.dispatch("acadre/loadAcadreDocumentStatusList")
      }
      this.acadreDocumentData.document_status_code = this.acadreDocumentStatusList.find(obj => obj.Literal === "B") || this.acadreDocumentStatusList[0];
      // Check for Acadre Connection
    }
    this.isLoading = false
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async sendDocumentsToAcadre() {
      if (!this.validateCaseNumber(this.case_number)) {
        this.inputValidationMessage = "Indtast veligst et sagsnummer du vil journalisere til."
      } else {
        this.inputValidationMessage = ""
        await this.$store.dispatch("acadre/checkCaseAvailability", this.case_number);
        if (this.caseAvailability && this.caseAvailability.status_code == 500) {
          this.inputValidationMessage = "Aktio kan ikke finde den sag du har indtastet."
          return
        }
        console.log("SAGSDATA", this.caseAvailability)
        const acadreDocumentObject = {
          document_category_code: this.acadreDocumentData.document_category_code.Literal,
          document_status_code: this.acadreDocumentData.document_status_code.Literal,
          publication_indicator: this.caseAvailability.RestrictedFromPublicText,
          case_file_manager_reference: this.caseAvailability.CaseFileManagerReference,
          document_type_code: this.acadreDocumentData.document_type_code.Literal,
          record_status_code: this.acadreDocumentData.record_status_code.Literal,
          process_id: this.$route.params.processId
        }
        const processData = {
          decision_letter_id: this.openProcess.decision_letter.id,
          process: {
            id: this.openProcess.id,
            process_id: this.openProcess.process_id
          }
        }
        const succesCreation = await this.$store.dispatch("acadre/sendDocumentsToCase", { caseData: this.caseAvailability, documentData: acadreDocumentObject, processData: processData });
        if (succesCreation) {
          this.closeModal()
        } else {
          if (this.errorMessage) {
            this.inputValidationMessage = this.errorMessage;
          } else {
            this.inputValidationMessage = "Der gik noget galt med journalisering af en Acadre sag. Kontakt Aktio hvis det fortsat fejler"
          }
        }
      }
    },
    validateCaseNumber(input) {
      const regex = /^\d{2}\/\d+$/;
      return regex.test(input)
    },
    async checkCaseAvailability() {
      await this.$store.dispatch("acadre/checkCaseAvailability", this.case_number);
    },
    async createCaseInAcadre() {
      if (this.hasNullOrEmptyValues(this.acadreCaseData)) {
        this.inputValidationMessage = "Sørg veligst for at alle felter er udfyldt før du opretter en sag i Acadre"
      } else {
        this.inputValidationMessage = ""

        const klKodeChosen = this.findObjectById(this.acadreOptions.categories.emne, this.acadreCaseData.emne)
        let customFields;
        let propertyData = undefined;
        if (this.acadreCaseData.case_file_type_code.Literal === "EJSAG" && this.ejendomCaseData) {
          propertyData = {
            street_building_identifier: this.ejendomCaseData.houseNumber,
            street_name: this.ejendomCaseData.streetName,
            post_code: this.ejendomCaseData.postCode,
            city: this.ejendomCaseData.city,
            property_number: this.ejendomCaseData.ejendomsnr,
            matrikel: this.ejendomCaseData.matrikelnummer
          }
          customFields = {
            df1: this.acadreCaseData.custom_fields_df1,
            df3: this.ejendomCaseData.matrikelnummer,
            df4: this.ejendomCaseData.bfeNumber,
          }
        } else {
          customFields = {
            df1: this.acadreCaseData.custom_fields_df1,
          }
        }
        const acadreCaseObject = {
          case_file_title_text: this.acadreCaseData.case_file_title_text,
          title_alternative_text: this.acadreCaseData.title_alternative_text,
          case_file_status_code: this.acadreCaseData.case_file_status_code.Literal, // Unique
          case_file_type_code: this.acadreCaseData.case_file_type_code.Literal, // Unique
          case_file_disposal_code: this.acadreCaseData.case_file_disposal_code.Literal || klKodeChosen.DefaultDiscardRule,
          case_file_manager_reference: this.acadreCaseData.case_file_manager_reference.Id,
          restricted_from_public_text: this.acadreCaseData.restricted_from_public_text.Id,
          administrative_unit: this.acadreCaseData.administrative_unit,
          access_code: this.acadreCaseData.access_code.Code,
          emne: this.acadreCaseData.emne,
          facet: this.acadreCaseData.facet,
          custom_fields: customFields,
          deletion_code: klKodeChosen.DeletionCode || "",
          // Predefined:
          case_file_paper_storage_indicator: this.acadreCaseData.case_file_paper_storage_indicator,
          title_unofficial_indicator: this.acadreCaseData.title_unofficial_indicator,
        }
        const acadreDocumentObject = {
          document_category_code: this.acadreDocumentData.document_category_code.Literal,
          document_status_code: this.acadreDocumentData.document_status_code.Literal,
          publication_indicator: this.acadreCaseData.restricted_from_public_text.Id,
          case_file_manager_reference: this.acadreCaseData.case_file_manager_reference.Id,
          document_type_code: this.acadreDocumentData.document_type_code.Literal,
          record_status_code: this.acadreDocumentData.record_status_code.Literal,
          process_id: this.$route.params.processId
        }
        const processData = {
          decision_letter_id: this.openProcess.decision_letter.id,
          process: {
            id: this.openProcess.id,
            process_id: this.openProcess.process_id
          }
        }
        const succesCreation = await this.$store.dispatch("acadre/createCase", { caseData: acadreCaseObject, documentData: acadreDocumentObject, processData: processData, propertyData: propertyData });
        if (succesCreation) {
          this.closeModal()
        } else {
          if (this.errorMessage) {
            this.inputValidationMessage = this.errorMessage;
          } else {
            this.inputValidationMessage = "Der gik noget galt med journalisering af en Acadre sag. Kontakt Aktio hvis det fortsat fejler"
          }
        }
      }
    },
  },
};
</script>

<style>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
</style>