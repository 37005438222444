<template>
  <redaction-button-exit
    class="absolute right-0 top-0 m-2"
    @emit-close="closeModal()"
  />
  <h3>
    Adgang til Acadre sager fra Aktio Indsigt
  </h3>
  <p>
    Hvis du ønsker at kunne hente Acadre sager fra Aktio Indsigt, skal du tildeles en rettighed og din leder skal godkende din nye rettighed
  </p>
  <p>
    Tryk på knappen herunder for at oprette en sag i Service Portalen, med anmodning om rettigheder til Acadre sager.
  </p>
  <a
    href="https://topdesk.sonderborg.alssok.net/tas/public/ssp/content/serviceflow?unid=e25d0ca81be8400396ad278a085f6bcd"
    target="_blank"
  >
    <standard-button
      button-type="standard"
      button-text="Tryk her for at oprette en sag i Service Portalen"
      title="Åben ny fane i Service Portalen"
      button-text-small
    />
  </a>
  <!-- <iframe 
    title="Acadre Integration anmod om adgang"
    width="900"
    height="700"
    src="https://topdesk.sonderborg.alssok.net/tas/public/ssp/content/serviceflow?unid=e25d0ca81be8400396ad278a085f6bcd"
  >
    Din browser understøtter ikke "iframe".
  </iframe>  -->
</template>

<script>
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "AcadreAccessForm",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton
  },
  methods: {
    closeModal() {
        this.$emit("close");
    },
  }
};
</script>