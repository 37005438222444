const getDefaultState = () => ({
    // URLS
    acadreUrl: process.env.VUE_APP_SERVER_URL + "/api/acadre",
    userUrl: process.env.VUE_APP_SERVER_URL + "/api/user",
    // Message Variables
    errorMessage: "",
    // Acadre Connection
    acadreConnectionStatus: true,
    // Acadre Login Status
    acadreLoginStatus: false,
    acadreUsername: "",
    acadreEncryptedPassword: "",
    // Acadre Case Data
    acadreCreateCaseId: null,
    acadreCaseStatusList: [],
    acadreCaseTypeList: [],
    acadreAccessCodeList: [],
    acadreAccessLevelList: [],
    acadreAdminUnitList: [],
    acadreUserList: [],
    acadreCategoryList: [],
    acadreDiscardRuleList: [],
    acadrePrimaryAdminUnit: {},
    // Acadre Person Data
    acadreSearchContactResults: [],
    acadrePerson: {},
    // Acadre Document Data
    acadreDocumentCategoryList: [],
    acadreDocumentStatusList: [],
    acadreMainDocumentTypeList: [],
    acadreMainDocumentStatusList: [],
    // ImportExternalDocumentsForm
    acadreCaseResult: [],
    acadreDocumentsResult: [],
    acadreSelectedDocuments: [],
    acadreDocumentsCount: 0,
    // EJCase
    ejendomCaseData: {},
    ejendomNumber: 0,
    // ExportExistingCase
    caseAvailability: false,
})

const acadre = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        setErrorMessage(state, payload) {
            state.errorMessage = payload
        },
        // Acadre Connection Status
        async setAcadreConnectionStatus(state, payload) {
            state.acadreConnectionStatus = await payload
        },
        // Acadre Login Status
        async setAcadreLoginStatus(state, payload) {
            state.acadreLoginStatus = await payload
        },
        async setAcadreEncryptedPass(state, payload) {
            state.acadreEncryptedPassword = await payload
        },
        // Acadre CreateCase Data
        setAcadreCreateCaseId(state, payload) {
            state.acadreCreateCaseId = payload
        },
        setAcadreCaseStatusList(state, payload) {
            state.acadreCaseStatusList = payload;
        },
        setAcadreCaseTypeList(state, payload) {
            state.acadreCaseTypeList = payload;
        },
        setAcadreAccessCodeList(state, payload) {
            state.acadreAccessCodeList = payload;
        },
        setAcadreAccessLevelList(state, payload) {
            state.acadreAccessLevelList = payload;
        },
        setAcadreAdminUnitList(state, payload) {
            state.acadreAdminUnitList = payload;
        },
        setAcadreUserList(state, payload) {
            state.acadreUserList = payload;
        },
        setAcadreCategoryList(state, payload) {
            state.acadreCategoryList = payload;
        },
        setAcadreDiscardRuleList(state, payload) {
            state.acadreDiscardRuleList = payload;
        },
        setAcadrePrimaryAdminUnit(state, payload) {
            state.acadrePrimaryAdminUnit = payload
        },
        // Acadre SelectPerson Data
        setAcadreSearchContactResults(state, payload) {
            state.acadreSearchContactResults = payload;
        },
        setAcadrePerson(state, payload) {
            state.acadrePerson = payload;
        },
        // Acadre Document Data
        setAcadreDocumentCategoryList(state, payload) {
            state.acadreDocumentCategoryList = payload;
        },
        setAcadreDocumentStatusList(state, payload) {
            state.acadreDocumentStatusList = payload;
        },
        setAcadreMainDocumentTypeList(state, payload) {
            state.acadreMainDocumentTypeList = payload;
        },
        setAcadreMainDocumentStatusList(state, payload) {
            state.acadreMainDocumentStatusList = payload;
        },
        // ImportExternalDocumentsForm
        setAcadreCaseResult(state, acadreCaseResult) {
            // Sort by CaseFileReference as an integer, from highest to lowest
            const sortedResult = acadreCaseResult.sort((a, b) => 
                parseInt(b.CaseFileReference) - parseInt(a.CaseFileReference)
            );
            state.acadreCaseResult = sortedResult;
        },
        setAcadreDocumentsResult(state, acadreDocumentsResult) {
            // Sort by DocumentIdentifier as an integer, from highest to lowest
            const sortedResult = acadreDocumentsResult.sort((a, b) => 
                parseInt(b.Document.DocumentIdentifier) - parseInt(a.Document.DocumentIdentifier)
            );
            state.acadreDocumentsResult = sortedResult;
        },
        setChosenAcadreDocumentsCount(state, acadreDocumentsResult) {
            state.acadreDocumentsCount = acadreDocumentsResult.length;
        },
        setEjendomCaseData(state, ejendomCaseData) {
            state.ejendomCaseData = ejendomCaseData
        },
        setEjendomNumber(state, ejendomNumber) {
            state.ejendomNumber = ejendomNumber
        },
        setCaseAvailability(state, caseAvailability) {
            state.caseAvailability = caseAvailability
        },
    },

    actions: {
        /* Acadre Connection Test Actions */
        // checkConnection
        async checkAcadreConnection({ commit, state, dispatch, rootState }) {
            console.log("Checking Acadre Connection...");
            
            let connectionResponse;
            await dispatch("loadUserFromAPI", {}, { root: true });
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                connectionResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getWSDL",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                console.error(error)
                dispatch("pushError", error, { root: true })
            }
            if (connectionResponse && connectionResponse.status_code === 200) {
                await commit("setAcadreConnectionStatus", true)
                console.log("Acadre connection succesful")
            } else {
                await commit("setAcadreConnectionStatus", false)
                console.log("Acadre connection failed")
            }
        },
        // checkLogin
        async checkAcadreLogin({ commit, state, dispatch }, loginObject) {
            let loginResponse;
            try {
                loginResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/loginCheck",
                    data: loginObject
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            if (loginResponse && loginResponse.status_code === 200) {
                await commit("setAcadreLoginStatus", true)
                let encryptedPass;
                try {
                    encryptedPass = await dispatch("apiRequest", {
                        method: "Post",
                        url: state.userUrl + "/encrypt",
                        data: {password: loginObject.password}
                    }, { root: true })
                } catch (error) {
                    dispatch("pushError", error, { root: true })
                }
                await commit("setAcadreEncryptedPass", encryptedPass)
            } else {
                await commit("setAcadreLoginStatus", false)
            }
        },
        /* Acadre CreateCase Data Actions */
        // CreateCase
        async createCase({ commit, state, dispatch, rootState }, dataObject) {
            let caseResponse;
            let documentResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj_case = {
                    ...user_info,
                    ...dataObject.caseData,
                }
                if (dataObject.propertyData) {
                    acadre_data_obj_case.property_data = {
                        ...user_info,
                        ...dataObject.propertyData,
                    }
                }
                caseResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/createCase",
                    data: acadre_data_obj_case
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            console.log("Create Case Status Code", caseResponse.status_code);
            if (caseResponse.status_code !== 200) {
                commit("setErrorMessage", await caseResponse.user_message)
                return false;
            }
            if (!(await caseResponse.case_file_indentifier)) {
                return false;
            }
            const processObject = {
                id: rootState.openProcess.id,
                acadre_case_id: await caseResponse.case_file_number_identifier
            }
            dispatch("updateProcess", processObject, { root: true })
            commit("setAcadreCreateCaseId", await caseResponse.case_file_number_identifier);
            
            dataObject.documentData.case_file_reference = await caseResponse.case_file_indentifier

            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj_doc = {
                    ...user_info,
                    ...dataObject.documentData,
                    decision_letter: dataObject.processData
                }
                documentResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/createDocument",
                    data: acadre_data_obj_doc
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreCreateCaseId", await documentResponse)
            return true;
        },
        // CaseStatusList
        async loadCaseStatusList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getCaseStatusList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreCaseStatusList", await remoteResponse);
        },
        // CaseTypeList
        async loadCaseTypeList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getCaseTypeList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreCaseTypeList", await remoteResponse);
        },
        // AccessCodeList
        async loadAccessCodeList({ commit, state, dispatch, rootState }, accessCodeData) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getAccessCodeList/"+accessCodeData.user_id+"/"+accessCodeData.admin_unit_id,
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreAccessCodeList", await remoteResponse);
        },
        // AccessLevelList
        async loadAccessLevelList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getAccessLevelList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreAccessLevelList", await remoteResponse);
        },
        // AdminUnitList
        async loadAdminUnitList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getAdminUnitList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreAdminUnitList", await remoteResponse);
        },
        // AdminUnitList
        async getUserPrimaryAdminUnit({ commit, state, dispatch, rootState }, acadreUserId) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getUserPrimaryAdminUnit/"+acadreUserId,
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadrePrimaryAdminUnit", await remoteResponse);
        },
        // UserList
        async loadUserList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getUserList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreUserList", await remoteResponse);
        },
        // CategoryList
        async loadCategoryList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getCategoryList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreCategoryList", await remoteResponse);
        },
        // DiscardRuleList
        async loadDiscardRuleList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getDiscardRuleList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreDiscardRuleList", await remoteResponse);
        },

        /* Acadre GetPerson Data Actions */
        // SearchContacts
        async searchContacts({ commit, state, dispatch, rootState }, searchTerm) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info,
                    search_term: searchTerm
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/searchContacts",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreSearchContactResults", await remoteResponse);
        },
        // GetPerson
        async getPerson({ commit, state, dispatch, rootState }, personGuid) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getPerson/" + personGuid,
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadrePerson", await remoteResponse);
        },
        /* Acadre Document Data Actions*/
        // acadreDocumentCategoryList
        async loadAcadreDocumentCategoryList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getDocumentCategoryList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreDocumentCategoryList", await remoteResponse);
        },
        // acadreDocumentStatusList
        async loadAcadreDocumentStatusList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getDocumentStatusList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreDocumentStatusList", await remoteResponse);
        },
        // acadreMainDocumentTypeList
        async loadAcadreMainDocumentTypeList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getMainDocumentTypeList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreMainDocumentTypeList", await remoteResponse);
        },
        // acadreMainDocumentStatusList
        async loadAcadreMainDocumentStatusList({ commit, state, dispatch, rootState }) {
            let remoteResponse;
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info
                }
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getMainDocumentStatusList",
                    data: acadre_data_obj
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreMainDocumentStatusList", await remoteResponse);
        },

        /* Import External Documents Actions */
        // SearchAcadreForCases
        async searchAcadreForCases({ commit, state, dispatch, rootState }, acadreSearchObj) {
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info,
                    ...acadreSearchObj
                }
                const response = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/searchCases",
                    data: acadre_data_obj
                }, { root: true });
                commit("setAcadreCaseResult", response);
            } catch (error) {
                dispatch("pushError", error, { root: true });
            }
        },
        // SearchAcadreForDocuments
        async searchAcadreForDocuments({ commit, state, dispatch, rootState }, acadreSearch) {
            let caseDocumentsResult = [];

            const caseSplit = acadreSearch.case_title.split("/");
            let caseYear = caseSplit[0];
            if (caseYear >= 0 && caseYear < 70) {
                caseYear = "20" + caseYear;
            } else {
                caseYear = "19" + caseYear;
            }
            const caseSeqNr = caseSplit[1];

            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj_case_nr = {
                    ...user_info,
                    case_year: caseYear,
                    case_seq_nr: caseSeqNr,
                }
                const response = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getCaseByCaseNumber",
                    data: acadre_data_obj_case_nr
                }, { root: true });

                const acadre_data_obj_documents = {
                    ...user_info,
                    case_id: String(response.CaseFileIdentifier)
                }
                caseDocumentsResult = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getAllDocuments",
                    data: acadre_data_obj_documents
                }, { root: true });

                /* 
                    Iterates over the "caseDocumentsResult" (documents) array and adds a "checked" property to each obj.
                    This "checked" property serves as a flag to indicate whether the obj has been selected (checked) in the UI.
                    Initially, all obj's are marked as unchecked ('checked: false').
                */
                caseDocumentsResult = caseDocumentsResult.map(doc => ({
                    ...doc,
                    acadreCase: {
                        CaseFileTitleText: response.CaseFileTitleText,
                        caseInfo: {
                            caseNumber: response.CaseFileNumberIdentifier,
                            kle: response.Classification.Category[1].Literal
                        },
                    },
                    checked: false // Initialize the 'checked' property to false
                }));
            } catch (error) {
                dispatch("pushError", error, { root: true });
            }
            
            commit("setAcadreDocumentsResult", caseDocumentsResult);
        },
        async updateDocumentSelectionState({ commit, state, dispatch, rootState }) {
            try {
                let updatedAcadreDocuments = state.acadreDocumentsResult.map(document => {
                    const isSelected = state.acadreSelectedDocuments.some(selectedDoc => selectedDoc.document.Document.DocumentIdentifier === document.Document.DocumentIdentifier);
                    return { ...document, checked: isSelected };
                });

                commit("setAcadreDocumentsResult", updatedAcadreDocuments);
            } catch (error) {
                dispatch("pushError", error, { root: true });
            }
        },
        async getAllSelectedDocumentsFromAcadre({ dispatch, state, rootState }, obj) {
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj = {
                    ...user_info,
                    process: {
                        id: obj.internalProcessId,
                        process_id: obj.processId
                    },
                    documents: state.acadreSelectedDocuments,
                }
                await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getAcadreDocument",
                    data: acadre_data_obj
                }, { root: true });
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
        },
        async getBBRNumber({ dispatch, state, commit }, bfeNumber) {
            let response
            try {
                response = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.acadreUrl + "/getEjendomNumber/" + bfeNumber,
                }, { root: true });
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setEjendomNumber", await response.ejendomsnummer);
        },
        async checkCaseAvailability({ dispatch, state, commit, rootState }, caseNumber) {
            let response
            let availability = false
            const caseSplit = caseNumber.split("/");
            let caseYear = caseSplit[0];
            if (caseYear >= 0 && caseYear < 70) {
                caseYear = "20" + caseYear;
            } else {
                caseYear = "19" + caseYear;
            }
            const caseSeqNr = caseSplit[1];
            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj_case_nr = {
                    ...user_info,
                    case_year: caseYear,
                    case_seq_nr: caseSeqNr,
                }
                response = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/getCaseByCaseNumber",
                    data: acadre_data_obj_case_nr
                }, { root: true });
            } catch (error) {
                console.error("Error sending request", error)
                dispatch("pushError", error, { root: true })
            }
            if (await response) {
                availability = await response
            }
            commit("setCaseAvailability", availability);
        },
        async sendDocumentsToCase({ commit, state, dispatch, rootState }, dataObject) {
            let documentResponse;
            if (!(dataObject.caseData.CaseFileIdentifier)) {
                return false;
            }
            const processObject = {
                id: rootState.openProcess.id,
                acadre_case_id: dataObject.caseData.CaseFileNumberIdentifier
            }
            dispatch("updateProcess", processObject, { root: true })
            commit("setAcadreCreateCaseId", dataObject.caseData.CaseFileNumberIdentifier);
            
            dataObject.documentData.case_file_reference = dataObject.caseData.CaseFileIdentifier

            try {
                let user_info;
                if (!rootState.user.userData.acadre_username) {
                    user_info = { encrypted_password: false }
                } else {
                    user_info = { encrypted_password: true, username: rootState.user.userData.acadre_username ?? "", password: rootState.user.userData.acadre_password ?? "" }
                }
                const acadre_data_obj_doc = {
                    ...user_info,
                    ...dataObject.documentData,
                    decision_letter: dataObject.processData
                }
                documentResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.acadreUrl + "/createDocument",
                    data: acadre_data_obj_doc
                }, { root: true })
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            commit("setAcadreCreateCaseId", await documentResponse)
            return true;
        },
    }
}


export default acadre;