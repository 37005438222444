<template>
  <div id="profile">
    <!-- <NavbarNew /> -->
    <div class="flex justify-center flex-col items-center">
      <div class="mt-6 text-4xl font-semibold mb-4">
        <span> Min profil</span>
      </div>
      <div class="text-lg">
        <span>Disse oplysninger benyttes i udkast til afgørelsesbrev.</span>
      </div>
      <h3
        v-if="error"
        class="mt-4 text-center text-red-500"
      >
        Der opstod desværre problemer med at ændre din profil. Prøv venligst igen.
      </h3>
    </div>
    <div
      class="flex justify-center bg-gray-100 mt-6"
    >
      <div
        v-show="!fetching"
        class="max-w-xl w-full"
      >
        <form
          class="w-full max-w-xl bg-white border rounded-lg w-fit p-6"
          @submit.prevent="updateUser"
        >
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label
                class="block tracking-wide text-gray-700 text-sm font-bold mb-1"
                for="grid-first-name"
              >
                Fornavn
              </label>
              <input
                id="grid-first-name"
                v-model="newUserData.first_name"
                aria-label="Fornavn"
                name="grid-first-name"
                type="text"
                autocomplete="off"
                required
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Fornavn"
                :disabled="disableProfileUpdating"
                @keydown="removeError()"
              >
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block tracking-wide text-gray-700 text-sm font-bold mb-1"
                for="grid-last-name"
              >
                Efternavn
              </label>
              <input
                id="grid-last-name"
                v-model="newUserData.last_name"
                aria-label="Efternavn"
                name="grid-last-name"
                type="text"
                autocomplete="off"
                required
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Efternavn"
                :disabled="disableProfileUpdating"
                @keydown="removeError()"
              >
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full px-3">
              <label
                class="block tracking-wide text-gray-700 text-sm font-bold mb-1"
                for="grid-job-title"
              >
                Titel
              </label>
              <input
                id="grid-job-title"
                v-model="newUserData.job_title"
                aria-label="Jobtitel"
                name="grid-job-title"
                autocomplete="off"
                type="text"
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Skriv din titel her"
              >
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label
                class="tracking-wide text-gray-700 text-sm font-bold mb-1 flex"
                for="grid-email"
              >
                E-mail <icon-asterisk class="w-3 h-3 text-red-500" />
              </label>
              <input
                id="grid-email"
                v-model="newUserData.email"
                aria-label="E-mailadresse"
                name="grid-email"
                autocomplete="off"
                type="email"
                required
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="E-mailadresse"
                :disabled="disableProfileUpdating"
              >
              <div
                v-if="emailError"
                class="text-red-600 text-sm mx-4"
              >
                {{ emailError }}
              </div>
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="tracking-wide text-gray-700 text-sm font-bold mb-1  flex"
                for="grid-phone-number"
              >
                Telefonnummer <icon-asterisk class="w-3 h-3 text-red-500" />
              </label>
              <input
                id="grid-phone-number"
                v-model="newUserData.phone_number"
                aria-label="Telefonnummer"
                name="grid-phone-number"
                autocomplete="off"
                type="tel"
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Skriv tlf nr."
              >
              <div
                v-if="phoneError"
                class="text-red-600 text-sm mx-4"
              >
                {{ phoneError }}
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full px-3">
              <label
                class="tracking-wide text-gray-700 text-sm font-bold mb-1 flex"
                for="grid-forvaltning"
              >
                Forvaltning
                <icon-asterisk class="w-3 h-3 text-red-500" />
              </label>
              <input
                id="grid-forvaltning"
                v-model="newUserData.administrations"
                aria-label="Forvaltning"
                name="grid-forvaltning"
                autocomplete="off"
                type="text"
                required
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Skriv forvaltningens navn"
              >
            </div>
          </div>
          <div class="-mx-3 mb-2">
            <div class="w-full px-3 mb-2 md:mb-0">
              <label
                class="tracking-wide text-gray-700 text-sm font-bold mb-1 flex"
                for="grid-department"
              >
                Afdeling <icon-asterisk class="w-3 h-3 text-red-500" />
              </label>
              <input
                id="grid-department"
                v-model="newUserData.department"
                aria-label="Afdeling"
                name="grid-department"
                autocomplete="off"
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Skriv navnet på din afdeling her"
              >
            </div>
            <div
              v-if="departmentError"
              class="text-red-600 text-sm mx-4"
            >
              {{ departmentError }}
            </div>
          </div>
          <div 
            v-show="acadreEnabled()" 
            class="-mx-3 mb-2"
          >
            <span class="pl-3 font-bold underline">Acadre Integration</span>
            <tooltip field_name="acadre_login" />
            <div class="w-full px-3 mb-2 md:mb-0">
              <label
                class="tracking-wide text-gray-700 text-sm font-bold mb-1 flex"
                for="new-acadre-username"
              >
                Windows Brugernavn
              </label>
              <input
                id="new-acadre-username"
                v-model="newUserData.acadre_username"
                aria-label="Acadre Username"
                name="new-acadre-username"
                autocomplete="off"
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Skriv dit brugernavn"
              >
              <label
                class="tracking-wide text-gray-700 text-sm font-bold mb-1 flex"
                for="new-acadre-password"
              >
                Windows Kodeord
              </label>
              <input
                id="new-acadre-password"
                v-model="newUserData.acadre_password"
                type="password"
                aria-label="Acadre Password"
                name="new-acadre-password"
                autocomplete="new-password"
                class="appearance-none relative block w-full px-4 py-3 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Skriv dit kodeord"
              >
            </div>
            <div
              v-if="acadreLoginError"
              class="text-red-600 text-sm mx-4"
            >
              {{ acadreLoginError }}
            </div>
          </div>
          <div class="flex mt-3">
            <button
              class="group relative w-full flex justify-center py-3 px-4 border text-sm leading-5 font-medium rounded-md text-white bg-aktio-darkblue-900 hover:bg-aktio-darkblue-800 focus:ring-blue-800"
            >
              Gem
            </button>
          </div>
        </form>
      </div>
      <div
        v-show="fetching"
        class="flex"
      >
        <redaction-icon-spinner class="text-black" />
        <span class="text-gray-500"> Henter oplysninger... </span>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import { reactive } from "vue";
// import { validationMixin } from "vuelidate";
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import iconSpinner from "@/components/icons/spinner.vue";
import IconAsterisk from "@/components/icons/asterisk.vue";
import Tooltip from "@/components/forms/tooltip.vue";
import FEATURE_FLAGS from "@/constants/featureflags";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default {
  name: "Profile",
  components: {
    "redaction-icon-spinner": iconSpinner,
    IconAsterisk,
    Tooltip,
  },
  // mixins: [validationMixin],
  setup() {    
    const newUserData = reactive({
      first_name: "",
      last_name: "",
      email: "",
      department: "",
      phone_number: "",
      administrations: "",
      job_title: "",
      username: "",
      acadre_username: "",
      acadre_password: "",
    });

    const rules = {
      first_name: {
        required,
      },
      last_name: {
        required,
      }
    };

    const v$ = useVuelidate(rules, newUserData, { $autoDirty: true });

    return {
      newUserData,
      v$
    };
  },
  data() {
    return {
      userId: "",
      firstLogin: false,
      disableProfileUpdating: false,
      allowed_to_order_rpa: false,
      error: false,
      fetching: true,
      emailError: undefined,
      sectionError: undefined,
      phoneError: undefined,
      departmentError: undefined,
      acadreLoginError: undefined
    };
  },
  computed: {
    ...mapState(["user", "jobSections", "surveys", "tooltips"]),
    ...mapState('acadre', [
      "acadreLoginStatus",
      "acadreEncryptedPassword"
    ]),
  },
  watch: {
    "user.userData"(userData) {
      this.newUserData = {
        ...this.newUserData,
        first_name : userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone_number: userData.phone_number,
        job_title: userData.job_title,
        administrations: userData.administrations,
        username: userData.username,
        department: userData.department
      }
      this.userId = userData.id
      this.firstLogin = userData.first_login
      this.allowed_to_order_rpa = userData.rpa_allowed
      this.fetching = false
    },
    "newUserData.email"(email){
      // Validate that an email exists, that the email is properly formatted and that the 
      // first part of the email is an exact match with the username (initially it will be when
      // created with kombit SSO)
      if(!email || email.length === 0){
        this.emailError = "Email skal udfyldes"
      } else if(validateEmail(email) === null){
        this.emailError = "Indtast venligst en korrekt email"
      } else if(email.split('@')[1] !== process.env.VUE_APP_CUSTOMER_EMAIL_DOMAIN){
        this.emailError = `Brug venligst en ${process.env.VUE_APP_CUSTOMER_EMAIL_DOMAIN} konto`
      } else if(email.split('@')[0] === this.newUserData.username){
        this.emailError = "Opdater venligst din email"
      } else {
        this.emailError = undefined;
      }  
      if(this.$featureflag(FEATURE_FLAGS.enableProfileUpdating)){
        return this.disableProfileUpdating = false;
      } else {
        return this.disableProfileUpdating = true;
      }
    },
  },
 
  async mounted() {
    const existingUserData = this.user.userData
    await this.$store.dispatch("loadJobSections");
    this.$store.dispatch("getTooltipsList")
    await this.$store.dispatch("getSurveys");

    // If we already have the user data stored locally,
    // use that data. Else fetch it again
    if(existingUserData && existingUserData.first_name) {
      this.newUserData = {
        ...this.newUserData,
        first_name: existingUserData.first_name,
        last_name: existingUserData.last_name,
        email: existingUserData.email,
        phone_number: existingUserData.phone_number,
        job_title: existingUserData.job_title,
        administrations: existingUserData.administrations,
        username: existingUserData.username,
        department: existingUserData.department,
      }
      this.userId = existingUserData.id;
      this.firstLogin = existingUserData.first_login
      this.allowed_to_order_rpa = existingUserData.rpa_allowed
      this.fetching = false
    } else {
      this.$store.dispatch("loadUserFromAPI");
    }
  },
  methods: {
    async updateUser() {
      this.sectionError = undefined;
      this.phoneError = undefined;
      this.departmentError = undefined;
      
      // Job section is mandatory, so ensure it's valid before 
      // moving on
      if(!this.newUserData.administrations) {
        this.sectionError = "Indtast venligst forvaltning"
        return
      }
      if(!this.newUserData.phone_number) {
        this.phoneError = "Vælg venligst et telefonnummer"
        return
      }
      if(!this.newUserData.department) {
        this.departmentError = "Vælg venligst en afdeling"
        return
      }
      // Test Acadre
      if (this.acadreEnabled() && this.newUserData.acadre_username && this.newUserData.acadre_password) {
        const acadreLoginObject = {
          username: this.newUserData.acadre_username,
          password: this.newUserData.acadre_password
        }
        await this.$store.dispatch("acadre/checkAcadreLogin", acadreLoginObject)
        if (!this.acadreLoginStatus) {
          this.acadreLoginError = "Acadre brugernavn eller kode kunne ikke valideres"
          return
        } else if (this.acadreLoginStatus)
        this.acadreEncryptedPassword
      }
      try {
        // If user has chosen a section, which has a rpa associated
        // add if user also is allowed to order RPA
        /* const allowed_to_order_rpa = {
          "rpa_allowed": this.newUserData.job_section_new.rpa_id && this.allowed_to_order_rpa
        } */

        const new_user_data = {
          user_id: this.userId,
          job_title: this.newUserData.job_title.toString(),
          phone_number: this.newUserData.phone_number.toString(),
          administrations: this.newUserData.administrations.toString(),
          department: this.newUserData.department,
          rpa_allowed: this.newUserData.rpa_allowed,
          acadre_username: this.newUserData.acadre_username,
          acadre_password: this.acadreEncryptedPassword,
          first_login: false,
        }

        this.newUserData = {...this.newUserData, first_login: false}
        // this.newUserData = {...this.newUserData, ...allowed_to_order_rpa, first_login: false}

        const response = await this.$store.dispatch("updateUser", new_user_data);
        
        // If update was successful, save the data locally and redirect
        if(response.status_code === 200){
          this.$store.dispatch("posthogCapture", {event_name:"EditMe", data: {}})

          if(this.firstLogin) {
            // if it is first login, go to the survey specific for first login
            console.log("First time login Survey currently disabled")
            // this.survey = this.$store.getters.getSurveyByGuid("3c9f5919-8b3c-4c09-a4af-6aca01844958")
            // this.$router.push({name: "Survey", params: {surveyGuid: this.survey.guid}})
            this.$router.push("/processes");
          } else {
            // Go to the main page
            this.$router.push("/processes");
          }
        } else {
          this.error = "Din profil kunne ikke opdateres. Kontakt venligst support."
        }            
      } catch (error) {
        this.error = error
        console.log(error)
      }
    },
    removeError() {
      this.error = false;
    },
    acadreEnabled() {
      return this.$featureflag(FEATURE_FLAGS.acadreLoginEnabled)
    }
  },
};
</script>