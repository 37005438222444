export default {
    file_is_defective: '257c1dde-3e80-4a48-9b26-9dafd6845091',
    file_had_read_error: 'a51d3608-fead-4d82-ac5a-f0b344ed7b97',
    file_convert_error: 'a2fa858e-6d9f-4f8a-90d4-ddc4dba836b0',
    file_is_ready_for_parsing: 'cafc2be0-a70f-4d85-832e-cd099d87a4c4',
    file_is_parsing: 'cd49a8d7-cc32-468e-afd0-330425783592',
    file_needs_passcode: 'ed0436f7-a035-4747-b87d-04d241637930',
    parsing_convertion: '9a2b32d4-ecc2-4d27-80fe-e8eff259c288',
    parsing_ocr: 'a49fc25e-7fec-4576-b013-93f3939655ac',
    parsing_text: 'e22d127c-2888-4aba-ae25-28819456ee1e',
    parsing_entities: 'df780ce7-e527-40d6-95b4-d00bcb2ce0e0',
    file_parsed: 'db7d36b0-2cf7-4c4f-8ae2-633d65137d40',
    file_paresd_no_ocr: 'a8c7c8df-8863-4567-81d2-5e7ed3f84ebc',
    file_excepted: '7ec3c1f7-1d95-4ae8-816a-be1782c09ef8',
    finished: '09923e90-8cfa-4444-9631-d1865e87f175',
    // These statuses are group statuses used to control functionality
    array_in_progress: ['cafc2be0-a70f-4d85-832e-cd099d87a4c4', 'cd49a8d7-cc32-468e-afd0-330425783592', '9a2b32d4-ecc2-4d27-80fe-e8eff259c288',
        'ed0436f7-a035-4747-b87d-04d241637930', 'a49fc25e-7fec-4576-b013-93f3939655ac',
        'e22d127c-2888-4aba-ae25-28819456ee1e', 'df780ce7-e527-40d6-95b4-d00bcb2ce0e0' ],
    array_error: ['257c1dde-3e80-4a48-9b26-9dafd6845091', 'a51d3608-fead-4d82-ac5a-f0b344ed7b97', 'a2fa858e-6d9f-4f8a-90d4-ddc4dba836b0' ],
    array_ready: ['db7d36b0-2cf7-4c4f-8ae2-633d65137d40', '09923e90-8cfa-4444-9631-d1865e87f175', 'a8c7c8df-8863-4567-81d2-5e7ed3f84ebc' ],
    array_excepted: ['7ec3c1f7-1d95-4ae8-816a-be1782c09ef8', ],
}